// Физические упражнения
export const physicalExercisesData = [
  {
    id: 1,
    name: "Разминка",
    description: "Найди немного время для разминки. Включает: 10 кругов вращения плечами, 10 наклонов в стороны, 10 кругов вращения тазом."
  },
  {
    id: 2,
    name: "Глубокое дыхание",
    description: "Выполните 5 минут глубокого дыхания: медленно вдыхайте через нос, задержите дыхание на 3 секунды и медленно выдыхайте через рот."
  },
  {
    id: 3,
    name: "Легкая растяжка",
    description: "Потянитесь вверх, затем наклонитесь вперед, стараясь коснуться пальцев ног. Повторите 5 раз."
  },
  {
    id: 4,
    name: "Приседания",
    description: "Выполните 10-15 приседаний, сохраняя спину прямой. Это упражнение помогает укрепить мышцы ног и ягодиц."
  },
  {
    id: 5,
    name: "Отжимания от стены",
    description: "Подойдите к стене, поставьте руки на уровне плеч и выполните 10-15 отжиманий, отталкиваясь от стены. Это лёгкий вариант для тренировки мышц груди и рук."
  },
  {
    id: 6,
    name: "Подъемы на носки",
    description: "Встаньте прямо и поднимитесь на носки 15-20 раз. Это упражнение укрепляет икроножные мышцы."
  },
  {
    id: 7,
    name: "Круговые движения плечами",
    description: "Сделайте по 10 круговых вращений плечами вперед и назад. Это упражнение помогает расслабить мышцы шеи и плеч."
  },
  {
    id: 8,
    name: "Медленные вращения туловищем",
    description: "Стоя прямо, выполните по 10 круговых вращений туловищем в каждую сторону, чтобы размять спину и бока."
  },
  {
    id: 9,
    name: "Скручивания на стуле",
    description: "Сядьте на стул, руки положите за голову, и выполните по 10 скручиваний туловища в каждую сторону, удерживая ноги неподвижными."
  },
  {
    id: 10,
    name: "Разгибание рук за головой",
    description: "Вытяните руки вверх, сцепите пальцы, и наклонитесь вправо, затем влево, растягивая боковые мышцы. Сделайте по 10 повторов на каждую сторону."
  },
  {
    id: 11,
    name: "Растяжка плеч и рук",
    description: "Вытяните одну руку перед собой, удерживая её другой рукой, и растягивайте мышцы плеча и руки. Повторите для другой стороны. Это упражнение помогает снять напряжение с плеч."
  },
  {
    id: 12,
    name: "Снять пенсне",
    description: "Когда чувствуете усталость глаз или есть проблемы со зрением, а также есть небольшая головная боль. Надо растереть ладошки друг об друга до ощущения тепла в середине ладоней. Любой удобной рукой большим и указательным пальцами наложить на внутренние углы глаз, надавливая вглубь и вниз умеренно, ощутимо 3 раза, отводя пальцы и снова нажимая."

  },
  {
    id: 13,
    name: "Снимаем напряжение с тела",
    description: "Найдите тихое место, сядьте или лягте. Напрягите пальцы ног на 5 секунд, затем расслабьте на 10 секунд. Продолжайте постепенно напрягать и расслаблять мышцы, двигаясь вверх по телу: икры, бедра, живот, грудь, руки, плечи, шею и лицо. Почувствуйте, что изменилось в теле по окончании этого упражнения."
  },
  {
    id: 14,
    name: "Упражнение с пальцами",
    description: "Большим и указательным пальцем одной руки обхватите большой палец другой руки в зоне ногтя с боковых сторон и нажимайте на ноготь  8-10 раз.  Затем по очереди также промассируйте остальные пальцы этой руки по 8-10 раз на каждый палец. Таким же образом массируйте пальцы другой руки. Затем обхватите запястье одной руки и покрутите вокруг него другой рукой 5-10 круговых движений. То-же самое проделайте с запястьем другой руки."
  },
];


