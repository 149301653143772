import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import "./App.css";

import Main from "../Main/Main";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AboutProject from "../AboutProject/AboutProject";
import PriceOfServices from "../PriceOfServices/PriceOfServices";
import AboutMe from "../AboutMe/AboutMe";
import Contacts from "../Contacts/Contacts";
import ContactForm from "../ContactForm/ContactForm";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import CookieConsent from "../CookieConsent/CookieConsent";
import NoAccess from "../NoAccess/NoAccess";
import InfoAboutCookies from "../InfoAboutCookies/InfoAboutCookies";
import ScientificCenter from "../ScientificCenter/ScientificCenter";

import HealthPage from "../HealthPage/HealthPage";
import CareerPage from "../CareerPage/CareerPage";
import PersonalLifePage from "../PersonalLifePage/PersonalLifePage";
import MotivationPage from "../MotivationPage/MotivationPage";
import TimeManagementPage from "../TimeManagementPage/TimeManagementPage";

import RandomQuote from "../RandomQuote/RandomQuote";
import DailyPractice from "../DailyPractice/DailyPractice";

const App = () => {
  const savedDarkMode = localStorage.getItem("darkMode");

  const [isDarkMode, setIsDarkMode] = useState(
    savedDarkMode ? JSON.parse(savedDarkMode) : false
  );

  const [cookieConsent, setCookieConsent] = useState(null);
  const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");

    const allowPrivacyPolicy = localStorage.getItem("allowPrivacyPolicy");
    if (consent === "declined" && !allowPrivacyPolicy) {
      navigate("/no-access");
    } else {
      setCookieConsent(consent);
    }
  }, [navigate]);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;

      localStorage.setItem("darkMode", JSON.stringify(newMode));
      return newMode;
    });
  };

  const themeClass = isDarkMode ? "dark-theme" : "";

  const handleGoPrice = () => {
    navigate("/price/");
  };

  return (
    <div className={`app ${isDarkMode ? "dark-theme" : "light-theme"}`}>
      <Header
        isDarkMode={isDarkMode}
        toggleTheme={toggleTheme}
        themeClass={themeClass}
        handleGoPrice={handleGoPrice}
      />

      <Routes>
        <Route
          path="/"
          element={
            <Main themeClass={themeClass} handleGoPrice={handleGoPrice} />
          }
        />

        <Route
          path="/about-project/"
          element={
            <AboutProject themeClass={themeClass} isDarkMode={isDarkMode} />
          }
        />
        <Route
          path="/health/"
          element={
            <HealthPage themeClass={themeClass} handleGoPrice={handleGoPrice} />
          }
        />
        <Route
          path="/career/"
          element={
            <CareerPage themeClass={themeClass} handleGoPrice={handleGoPrice} />
          }
        />
        <Route
          path="/personal-life/"
          element={
            <PersonalLifePage
              themeClass={themeClass}
              handleGoPrice={handleGoPrice}
            />
          }
        />
        <Route
          path="/motivation/"
          element={<MotivationPage themeClass={themeClass} />}
        />
        <Route
          path="/time-management/"
          element={
            <TimeManagementPage
              themeClass={themeClass}
              handleGoPrice={handleGoPrice}
            />
          }
        />
        <Route
          path="/quote/"
          element={<RandomQuote themeClass={themeClass} />}
        />
        <Route
          path="/daily-practice/"
          element={<DailyPractice themeClass={themeClass} />}
        />
        <Route
          path="/price/"
          element={<PriceOfServices themeClass={themeClass} />}
        />
        <Route
          path="/about-me/"
          element={<AboutMe themeClass={themeClass} isDarkMode={isDarkMode} />}
        />
        <Route
          path="/contacts/"
          element={<Contacts themeClass={themeClass} />}
        />
        <Route
          path="/contact-form/:sessionType"
          element={<ContactForm themeClass={themeClass} />}
        />
        <Route
          path="/scientific-center/"
          element={<ScientificCenter themeClass={themeClass} />}
        />
        <Route
          path="/privacy-policy/"
          element={
            <PrivacyPolicy
              themeClass={themeClass}
              setCookieConsent={setCookieConsent}
              setIsCookieConsentVisible={setIsCookieConsentVisible}
            />
          }
        />
        <Route
          path="/about-cookies/"
          element={
            <InfoAboutCookies
              setCookieConsent={setCookieConsent}
              setIsCookieConsentVisible={setIsCookieConsentVisible}
              themeClass={themeClass}
            />
          }
        />
        <Route path="*" element={<NotFoundPage themeClass={themeClass} />} />
        <Route
          path="/no-access/"
          element={
            <NoAccess
              setCookieConsent={setCookieConsent}
              setIsCookieConsentVisible={setIsCookieConsentVisible}
              themeClass={themeClass}
            />
          }
        />
      </Routes>
      <Footer themeClass={themeClass} isDarkMode={isDarkMode} />

      {cookieConsent !== "accepted" && isCookieConsentVisible && (
        <>
          <CookieConsent
            themeClass={themeClass}
            setCookieConsent={setCookieConsent}
          />
          {/* <div className="overlay"></div> */}
        </>
      )}
    </div>
  );
};

export default App;
