import React, { useState, useEffect } from "react";

import PageHelmet from "../PageHelmet";
import MyEducationPhoto from "../../images/MyEducationPhoto.png";

import axios from "axios";
import "./RandomQuote.css";

const RandomQuote = ({ themeClass }) => {
  const [quote, setQuote] = useState({
    quoteText: "Дайте вдохновению время найти вас",
    quoteAuthor: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [canGenerate, setCanGenerate] = useState(true);

  useEffect(() => {
    if (!canGenerate) {
      const timer = setTimeout(() => setCanGenerate(true), 180000); // 3 минуты
      return () => clearTimeout(timer);
    }
  }, [canGenerate]);

  const fetchQuote = async () => {
    setLoading(true);
    setError(null);

    const url =
      "https://api.forismatic.com/api/1.0/?method=getQuote&format=json&lang=ru";
    const proxyUrl =
      "https://api.allorigins.win/get?url=" + encodeURIComponent(url);

    try {
      const response = await axios.get(proxyUrl);
      // Данные приходят в виде JSON-строки, нужно их распарсить
      const data = JSON.parse(response.data.contents);
      setQuote(data);
      setCanGenerate(false);
    } catch (error) {
      setError("Не удалось загрузить цитату. Попробуйте снова.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={`quote ${themeClass}`} id="quote">
      <PageHelmet
        title="Мотивирующие психологические цитаты | Ирина Медведева"
        description="Получите вдохновение с помощью случайных психологических цитат. Каждая цитата поможет вам задуматься, найти новые силы и обрести мотивацию для изменений."
        keywords="психологические цитаты, мотивация, вдохновение, Ирина Медведева, коуч, цитаты для саморазвития"
        image={MyEducationPhoto}
        canonicalUrl="https://yourcoachirina.ru/quote/"
      />
      <div className={`quote__wrapper ${themeClass}`}>
        <h2 className={`quote__title ${themeClass}`}>
          Нажмите кнопку и впустите мудрость в свою жизнь.
        </h2>

        <div
          className={`quote__box ${themeClass} ${
            loading ? "quote__box-loading" : ""
          }`}
        >
          {loading && <p>Загрузка...</p>}
          {error && <p>{error}</p>}
          {quote && !loading && (
            <blockquote>
              "{quote.quoteText}"
              <footer>- {quote.quoteAuthor || "Неизвестный автор"}</footer>
            </blockquote>
          )}
        </div>

        <button
          className={`quote__button ${themeClass}`}
          onClick={fetchQuote}
          disabled={!canGenerate}
        >
          Узнать
        </button>
        <p className={`quote__description ${themeClass}`}>
          Не торопитесь. Дайте себе 3 минуты на раздумье.
        </p>
      </div>
    </section>
  );
};

export default RandomQuote;
