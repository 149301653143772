import React from "react";
import { Link } from "react-router-dom";

import "./ForWhom.css";

import ForWhomPhoto from "../../images/ForWhomPhoto.png";
import flagL from "../../images/flagL.png";
import flagD from "../../images/flagD.png";
import bagL from "../../images/bagL.png";
import bagD from "../../images/bagD.png";
import headerL from "../../images/heardL.png";
import headerD from "../../images/heardD.png";
import timeL from "../../images/timeL.png";
import timeD from "../../images/timeD.png";
import emotion1 from "../../images/emotion1.png";

const ForWhom = ({ themeClass, isDarkMode }) => {
  return (
    <section className={`for-whom ${themeClass}`} id="for-whom">
      <div className="for-whom__wrapper">
        <div className="for-whom__title">
          <h1 className={`for-whom__title-big ${themeClass}`}>Коучинг</h1>
          <h2 className={`for-whom__title-small ${themeClass}`}>
            для женщин <br /> и всех, кто стремится к гармонии
          </h2>
        </div>
        <div className="for-whom__blocks">
          <div className="for-whom__block">
            <div className="for-whom__line">
              <img
                className="for-whom__block-img"
                src={emotion1}
                alt="значок здоровья: здоровье как основа гармоничной жизни"
              />
              <Link
                to="/health"
                className={`for-whom__block-text ${themeClass}`}
              >
                здоровье как основа гармоничной жизни
              </Link>
            </div>
            <div className="for-whom__line">
              <img
                className="for-whom__block-img"
                src={isDarkMode ? bagD : bagL}
                alt="Значок портфеля: профессиональное развитие и карьера"
              />
              <Link
                to="/career"
                className={`for-whom__block-text ${themeClass}`}
              >
                найти пути развития в профессии или построении своего бизнеса
              </Link>
            </div>
            <div className="for-whom__line">
              <img
                className="for-whom__block-img"
                src={isDarkMode ? headerD : headerL}
                alt="Значок двух рук с сердцем: наладить гармоничную личную жизнь "
              />
              <Link
                to="/personal-life"
                className={`for-whom__block-text ${themeClass}`}
              >
                наладить гармоничную личную жизнь
              </Link>
            </div>
            <div className="for-whom__line">
              <img
                className="for-whom__block-img"
                src={isDarkMode ? flagD : flagL}
                alt="значок человека на лестнице: найти мотивацию для достижения своей цели"
              />
              <Link
                to="/motivation"
                className={`for-whom__block-text ${themeClass}`}
              >
                найти мотивацию для достижения своей цели
              </Link>
            </div>
            <div className="for-whom__line">
              <img
                className="for-whom__block-img"
                src={isDarkMode ? timeD : timeL}
                alt="Значок фотоаппарата: с пользой распределять время между работой и отдыхом"
              />
              <Link
                to="/time-management"
                className={`for-whom__block-text ${themeClass}`}
              >
                с пользой распределять время между работой и отдыхом
              </Link>
            </div>
          </div>

          <img
            className={`for-whom__photo ${themeClass}`}
            src={ForWhomPhoto}
            alt="фотография цветка в вазе: Коучинг для женщин и всех, кто стремится к гармонии"
          />
        </div>
      </div>
    </section>
  );
};

export default ForWhom;
