import React from "react";

import WillChange from "../WillChange/WillChange";
import Process from "../Process/Process";
import ForWhom from "../ForWhom/ForWhom";
import Questions from "../Questions/Questions";
import Telegram from "../Telegram/Telegram";

import PageHelmet from "../PageHelmet";

import "./AboutProject.css";
import TrustMePhoto from "../../images/TrustMePhoto.png";

const AboutProject = ({ themeClass, isDarkMode }) => {
  return (
    <nav className={`about-project ${themeClass}`}>
      <PageHelmet
        title="Помогаю раскрыть Ваш потенциал | Ирина Медведева."
        description="Ирина Медведева. Коуч ACC ICF, психолог. Помогу мотивировать себя и не отходить от поставленной цели."
        keywords="ACC, ICF, коуч, ваш коуч, Ирина Медведева"
        image={TrustMePhoto}
        canonicalUrl="https://yourcoachirina.ru/about-project"
      />
      <ForWhom themeClass={themeClass} isDarkMode={isDarkMode} />
      <WillChange themeClass={themeClass} />
      <Process themeClass={themeClass} />
      <Questions themeClass={themeClass} isDarkMode={isDarkMode} />
      <Telegram themeClass={themeClass} />
    </nav>
  );
};
export default AboutProject;
