import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "./Header.css";

import NavTab from "../NavTab/NavTab";
import LightTheme from "../../images/LightTheme.svg";
import DarkTheme from "../../images/DarkTheme.svg";
import logoDark from "../../images/logoDark.png";
import logoLight from "../../images/logoLight.png";

const Header = ({ themeClass, isDarkMode, toggleTheme, handleGoPrice }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoMain = () => {
    navigate("/");
  };

  return (
    <header className={`header ${themeClass}`}>
      <div className="header__wrapper ">
        <div className="header__logo hover" onClick={handleGoMain}>
          <img src={isDarkMode ? logoLight : logoDark} alt="логотип сайта" />
        </div>

        <NavTab themeClass={themeClass} />

        <div className="header__block">
          {location.pathname !== "/privacy-policy/" &&
            location.pathname !== "/price/" && (
              <button className="header__btn" onClick={handleGoPrice}>
                Хочу в коучинг
              </button>
            )}

          <div className="header__theme hover" onClick={toggleTheme}>
            <img
              src={isDarkMode ? LightTheme : DarkTheme}
              alt="Toggle Theme"
              title="СМЕНИТЬ ТЕМУ ЭКРАНА"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
