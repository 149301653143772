import React, { useEffect } from "react";

import "./Contacts.css";
import MyEducationPhoto from "../../images/MyEducationPhoto.png";
import ContactsPhoto from "../../images/ContactsPhoto.png";
import useHeaderHeight from "../../hooks/useHeaderHeight";
import PageHelmet from "../PageHelmet";

const phoneNumber = "+79161362425 ";
const phoneUserName = "irinamedvedeva_psy_coach";

const Contacts = ({ themeClass }) => {
  const headerHeight = useHeaderHeight();

  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleTelegramClick = () => {
    const telegramUrl = `https://t.me/${phoneUserName}`;
    window.open(telegramUrl, "_blank");
  };

  useEffect(() => {
    const header = document.querySelector(".header");
    const headerHeight = header ? header.offsetHeight : 0;

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className={`contacts ${themeClass}`} id="contacts">
      <PageHelmet
        title="Контакты | Ирина Медведева."
         description="Добро пожаловать на сайт Ирины Медведевой, профессионального коуча и психолога."
        keywords="коучинг, психолог, самооценка, Ирина Медведева, проработать травмы"
        image={MyEducationPhoto}
        canonicalUrl="https://yourcoachirina.ru/contacts"
      />
      <div
        className="contacts__wrapper"
        style={{ paddingTop: `${headerHeight}px` }}
      >
        <div className={`contacts__container ${themeClass}`}></div>

        <div className="contacts__block">
          <h1 className={`contacts__title ${themeClass}`}>Ирина Медведева</h1>
          <img
            className={`contacts__photo ${themeClass}`}
            src={ContactsPhoto}
            alt="Фотография коуча"
          />
          <h2 className={`contacts__subtitle ${themeClass}`}>
            Ваш коуч и психолог
          </h2>
          <p className={`contacts__text ${themeClass}`}>
            Есть вопросы или сомнения?
            <br />
            Свяжитесь со мной в WhatsApp или Telegram      
          </p>

          <button
            className={`contacts__btn contacts__btn-telegram hover ${themeClass}`}
            onClick={handleTelegramClick}
          >
            Telegram
          </button>

          <button
            className={`contacts__btn contacts__btn-whatsapp hover ${themeClass}`}
            onClick={handleWhatsAppClick}
          >
            WhatsApp
          </button>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
