import React from "react";
import { useNavigate } from "react-router-dom";

import "./Footer.css";
import cycounterlight from "../../images/cycounterLight.svg";
import cycounterDark from "../../images/cycounterDark.svg";

const Footer = ({ themeClass, isDarkMode }) => {
  const todaytYear = new Date().getFullYear();

  const navigate = useNavigate();

  const handlePrivacyPolicyClick = () => {
    navigate("/privacy-policy");
  };

  const themeParam = isDarkMode ? "dark" : "light";

  return (
    <footer className={`footer ${themeClass}`}>
      <div className="footer__wrapper">
        <div className={`footer__block ${themeClass}`}>
          <h1 className={`footer__title ${themeClass}`}>Ирина Медведева</h1>
          <div className={`footer__block ${themeClass}`}>
            <div className={`footer__copyright ${themeClass}`}>
              {todaytYear}
            </div>{" "}
          </div>
        </div>
        <div className={`footer__block-info ${themeClass}`}>
          <button
            className={`footer__btn hover ${themeClass}`}
            onClick={handlePrivacyPolicyClick}
          >
            политика конфиденциальности
          </button>

          <a
            href={`https://webmaster.yandex.ru/siteinfo/?site=https://yourcoachirina.ru`}
          >
            <img
              width="78"
              height="25"
              border="0"
              src={isDarkMode ? cycounterDark : cycounterlight}
              alt="Счётчик Яндекс"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
