import React from "react";

import PageHelmet from "../PageHelmet";

import motivation4 from "../../images/motivation4.png";

const MotivationPage = ({ themeClass, handleGoPrice }) => {
  return (
    <div className={`career-page ${themeClass}`}>
      <PageHelmet
        title="Мотивация | Коучинг с Ириной Медведевой"
        description="Запишитесь на коуч-сессию с Ириной Медведевой для поиска мотивации для достижения своей цели. Найди источник внутренней силы."
        keywords="коучинг мотивации, достижение цели, коуч для мотивации, преодоление преград, Ирина Медведева, профессиональное развитие, лайф коуч, источник внутренней силы"
        image={motivation4}
        canonicalUrl="https://yourcoachirina.ru/motivation/"
      />
      <div className="career-page__wrapper">
        <div className="career-page__title-block">
          <h1 className={`career-page__title ${themeClass}`}>
            Найти мотивацию для достижения своей цели
          </h1>
          <img
            className="career-page__img-small"
            src={motivation4}
            alt="мотивация для достижения своей цели"
          />
        </div>
        <p className={`career-page__text ${themeClass}`}>
          Иногда мотивация угасает, и кажется, что цели становятся
          недосягаемыми. На наших коуч-сессиях вы научитесь находить источник
          внутренней силы, ставить чёткие цели и достигать их шаг за шагом.
          Вместе мы преодолеем любые преграды на вашем пути.
          <br /> <br />
          Запишитесь на сессию, чтобы обрести новую энергию для достижения ваших
          целей.
        </p>
        <button
          className={`career-page__btn-price hover ${themeClass}`}
          onClick={handleGoPrice}
        >
          Хочу в коучинг
        </button>
      </div>
    </div>
  );
};

export default MotivationPage;
