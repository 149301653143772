import React from "react";

import PageHelmet from "../PageHelmet";

import "./CareerPage.css";
import analysis from "../../images/analysis.png";

const CareerPage = ({ themeClass, handleGoPrice }) => {
  return (
    <div className={`career-page ${themeClass}`}>
      <PageHelmet
        title="Развитие карьеры и бизнеса | Коучинг с Ириной Медведевой"
        description="Запишитесь на коуч-сессию с Ириной Медведевой для поиска путей развития в карьере или построения бизнеса. Разберитесь в своих сильных сторонах и начните путь к успеху."
        keywords="коучинг карьеры, развитие бизнеса, коуч для бизнеса, карьерный рост, Ирина Медведева, профессиональное развитие, бизнес коуч, построение карьеры"
        image={analysis}
        canonicalUrl="https://yourcoachirina.ru/career/"
      />
      <div className="career-page__wrapper">
        <div className="career-page__title-block">
          <h1 className={`career-page__title ${themeClass}`}>
            Найти пути развития в профессии или построении своего бизнеса
          </h1>
          <img
            className="career-page__img-small"
            src={analysis}
            alt="построение своего бизнеса"
          />
        </div>
        <p className={`career-page__text ${themeClass}`}>
          Если вы стоите на распутье в своей карьере или бизнесе, возможно, вам
          не хватает ясности и уверенности в своих шагах. На сессиях мы поможем
          вам разобраться в ваших сильных сторонах, раскрыть скрытые таланты и
          определить конкретные шаги для продвижения вперед. Пора создавать
          будущее, которое вы хотите!
          <br /> <br />
          Запишитесь на сессию и начните строить карьеру своей мечты уже
          сегодня!
        </p>

        <button
          className={`career-page__btn-price hover ${themeClass}`}
          onClick={handleGoPrice}
        >
          Хочу в коучинг
        </button>
      </div>
    </div>
  );
};

export default CareerPage;
