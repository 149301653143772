import React from "react";
import { Helmet } from "react-helmet-async";
import BaseHelmet from './BaseHelmet';

const PageHelmet = ({ title, description, keywords, image, canonicalUrl }) => (
  <>
    <BaseHelmet />
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={canonicalUrl} />
    </Helmet>
  </>
);

export default PageHelmet;
