import React from 'react';
import { Helmet } from 'react-helmet-async';

const BaseHelmet = () => (
  <Helmet>
    <meta name="author" content="Ирина Медведева" />
    <meta name="copyright" content={`Copyright © ${new Date().getFullYear()} Your Coach Irina`} />
    <meta property="og:type" content="website" />
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "WebPage",
        "author": {
          "@type": "Person",
          "name": "Ирина Медведева"
        },
        "copyrightHolder": {
          "@type": "Person",
          "name": "Ирина Медведева"
        },
        "copyrightYear": new Date().getFullYear(),
        "publisher": {
          "@type": "Organization",
          "name": "Ваш коуч и психолог Ирина"
        }
      })}
    </script>
  </Helmet>
);

export default BaseHelmet;
