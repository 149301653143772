import React, { useEffect } from "react";

import "./Main.css";
import MainPhoto from "../../images/MainPhoto.png";

import useHeaderHeight from "../../hooks/useHeaderHeight";
import PageHelmet from "../PageHelmet";

const Main = ({ themeClass, handleGoPrice }) => {
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    const header = document.querySelector(".header");
    const headerHeight = header ? header.offsetHeight : 0;

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className={`main ${themeClass}`} id="main">
      <PageHelmet
        title="Главная страница - Ваш коуч и психолог Ирина Медведева."
        description="Добро пожаловать на сайт Ирины Медведевой, профессионального коуча и психолога."
        keywords="психолог, ваш коуч, раскрыть потенциал, Ирина Медведева, Коуч ACC"
        image={MainPhoto}
        canonicalUrl="https://yourcoachirina.ru"
      />
      <div
        className="main__wrapper "
        style={{ paddingTop: `${headerHeight}px` }}
      >
        <img
          className={`main__photo ${themeClass}`}
          src={MainPhoto}
          alt="фотография автора"
        />

        <div className={`main__blok-info ${themeClass}`}>
          <h1 className="main__title">Ирина Медведева</h1>

          <h2 className="main__subtitle">коуч ACC ICF, психолог</h2>
          <p className="main__text">
            Поддерживаю Вас и помогаю раскрыть Ваш потенциал на пути к
            достижению результата.
          </p>
          <button
            className={`main__link hover ${themeClass}`}
            onClick={handleGoPrice}
          >
            Хочу в коучинг
          </button>
        </div>
      </div>
    </section>
  );
};

export default Main;
