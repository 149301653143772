export const spiritualExercisesData = [
  {
    id: 1,
    name: "Медитация осознанности",
    description: "Посвятите 10 минут медитации, сосредоточившись на своем дыхании и отпуская любые негативные мысли."
  },
  {
    id: 2,
    name: "Сочинение благодарностей",
    description: "Потратьте 5 минут, чтобы записать три вещи, за которые вы благодарны сегодня."
  },
  {
    id: 3,
    name: "Легкий самомассаж",
    description: "Проведите легкий массаж плеч, шеи и головы в течение 5 минут, чтобы снять напряжение."
  },
  {
    id: 4,
    name: "Дыхательная гимнастика",
    description: "Закройте глаза и сделайте 20 глубоких вдохов, концентрируясь на медленном, осознанном дыхании. Это поможет восстановить внутренний баланс и снизить стресс."
  },
  {
    id: 5,
    name: "Практика благодарности",
    description: "Напишите три вещи, за которые вы благодарны сегодня. Это упражнение поможет вам развивать позитивное мышление и замечать радости даже в мелочах."
  },
  {
    id: 6,
    name: "Визуализация целей",
    description: "Представьте свои главные жизненные цели так, будто они уже достигнуты. Почувствуйте радость и удовлетворение от этого. Это упражнение помогает привлекать позитивные изменения."
  },
  {
    id: 7,
    name: "Утренний ритуал осознанности",
    description: "Начните утро с 5 минут тишины, прислушиваясь к своему телу и мыслям. Это помогает настраиваться на продуктивный день и сохранять осознанность."
  },
  {
    id: 8,
    name: "Аффирмации",
    description: "Прочтите вслух или напишите позитивные утверждения, которые помогают вам верить в себя и свои силы. Повторение аффирмаций развивает уверенность в себе."
  },
  {
    id: 9,
    name: "Релаксация через музыку",
    description: "Найдите расслабляющую музыку и посвятите 15 минут медитации или просто слушанию, фокусируясь на звуках и своих ощущениях."
  },
  {
    id: 10,
    name: "Запись внутренних переживаний",
    description: "Ведите дневник, где записывайте свои мысли и эмоции. Это упражнение помогает лучше понять себя и снять внутреннее напряжение."
  },
  {
    id: 11,
    name: "Самонаблюдение за эмоциями",
    description: "В течение дня отслеживайте свои эмоции, отмечая моменты стресса или радости. Это поможет вам лучше понимать свои эмоциональные триггеры."
  },
  {
    id: 12,
    name: "Медитация на любовь",
    description: " Посвятите 10 минут медитации на добрые пожелания себе и другим людям. Представьте, что вы посылаете им свет и позитивные мысли."
  },
  {
    id: 13,
    name: "Практика осознанного питания",
    description: "Следующее прием пищи сделайте полностью осознанным, медленно и внимательно ощущая вкус, текстуру и запах еды."
  },
  {
    id: 14,
    name: "Медитация на звук",
    description: "Сядьте в удобное место и сфокусируйтесь на окружающих звуках. Это может быть пение птиц, ветер или любой другой звук. Погружайтесь в слушание, отпуская мысли."
  },
  {
    id: 15,
    name: "Медитация сканирования тела",
    description: "Лежа или сидя с закрытыми глазами, медленно 'сканируйте' свое тело, начиная с кончиков пальцев ног и заканчивая макушкой, отмечая любые ощущения."
  },
  {
    id: 16,
    name: "Практика самоисцеления через дыхание",
    description: "Вдохните, представляя, как с каждым вдохом в вас входит свет и энергия. На выдохе представляйте, как уходит вся усталость и напряжение."
  },


  {
    "id": 17,
    "name": "Техника 5-4-3-2-1 для снижения стресса",
    "description": "Чтобы отвлечься от тревожных мыслей, выполните следующие шаги:\n\n" +
      "1. Найдите 5 вещей, которые вы видите;\n" +
      "2. Найдите 4 вещи, которые вы можете потрогать;\n" +
      "3. Найдите 3 вещи, которые вы можете услышать;\n" +
      "4. Найдите 2 вещи, от которых вы можете почувствовать запах;\n" +
      "5. Найдите 1 вещь, которую вы можете попробовать на вкус."
  },
  {
    id: 18,
    name: " КВАДРАТНОЕ ДЫХАНИЕ",
    description: "Простая дыхательная техника помогает быстро успокоиться и снизить уровень стресса:\n\n" +
      "1. Закройте глаза, сосредоточьтесь на себе и своих ощущениях, расслабьтесь;\n" +
      "2. Вдохните и считайте про себя 1, 2, 3, 4 ;\n" +
      "3. Задержите дыхание, также считайте 1, 2, 3, 4 ;\n" +
      "4. Выдохните, считайте 1, 2, 3, 4;\n" +
      "5. Задержите дыхание, считайте 1, 2, 3, 4.\n\n" +
      "Повторяйте несколько раз, фиксируя свое внимание на ритме дыхания, пока не почувствуете расслабление в теле."
  },
  {
    id: 19,
    name: "Снижаем градус обид",
    description: "Если обида застряла в сердце и вы постоянно предъявляете её тем, кто сделал вам больно, продолжая обвинять, осуждать и страдать, то это упражнение поможет вам снизить градус обиды и отпустить ее:\n\n" +
      "Вспомните эпизод из жизни, в котором вы до сих пор чувствуете обиду и отпустите ее, произнося слова «Я отпускаю эту ситуацию и благодарю за нее». Повторите эти слова несколько раз, пока не почувствуете, что ваше отношение к этой ситуации меняется. Понаблюдайте, как вы себя чувствуете после этого."
  },
  {
    id: 20,
    name: "Небо и вы",
    description: "Встаньте у окна и посмотрите в небо. Это упражнение можно делать на природе у воды или у дерева. Скажите несколько раз «Я прощаю и отпускаю, прощаю и отпускаю». Просто проговаривайте эту фразу  и отслеживайте ощущения в своем теле, что меняется внутри вас с каждой фразой?"
  },
  {
    id: 21,
    name: "Активация внутренней энергии в теле",
    description: "Большим и указательным пальцами рук начинайте растирать, массировать свои уши от мочки  вверх по всей раковине уха в течение 1-2 минут. Отдельно помассируйте козелок уха (небольшой хрящик около слухового прохода). Почувствуйте тепло в зоне ушей. Затем Разотрите ладони и теплые ладони положите на уши, ощутите как тепло от ладоней проходит внутрь уха и наполняет  голову и растекается по всему телу! Будьте здоровы!"
  },
];
