import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "./ContactForm.css";
import MyEducationPhoto from "../../images/MyEducationPhoto.png";

import PageHelmet from "../PageHelmet";

const ContactForm = ({ themeClass }) => {
  const { sessionType } = useParams();

  const sessionsData = [
    {
      type: "free-session",
      description: "Запись на бесплатную ознакомительную сессию",
      containerText:
        "Первая коуч-сессия бесплатная и длится 30 минут. Цель этой сессии - познакомиться, обсудить тему вашего обращения и ожидания от коучинга. Мы также согласуем условия дальнейшего взаимодействия.",
      subtitle:
        "Сделайте первый шаг к своим целям! Запишитесь на бесплатную сессию и начните путь к изменениям уже сегодня.",
      seoTitle: "Бесплатная коуч-сессия с Ириной Медведевой",
      seoDescription:
        "Запишитесь на бесплатную ознакомительную коуч-сессию с Ириной Медведевой и начните свой путь к изменениям.",
      seoKeywords: "бесплатная коуч-сессия, Ирина Медведева, коучинг",
    },
    {
      type: "8-sessions",
      description: "Запись на 8 коуч-сессий",
      containerText:
        "Программа из 8 коуч-сессий для глубокого анализа и достижения ваших целей. Вы получите поддержку и структуру, необходимые для устойчивого прогресса и изменений в вашей жизни.",
      subtitle:
        "Готовы к серьезным переменам? Запишитесь на 8 сессий и начните трансформировать свою жизнь.",
      seoTitle: "8 коуч-сессий с Ириной Медведевой",
      seoDescription:
        "Запишитесь на программу из 8 коуч-сессий с Ириной Медведевой для достижения ваших целей и устойчивого прогресса.",
      seoKeywords: "коуч-сессии, Ирина Медведева, трансформация",
    },
    {
      type: "10-sessions",
      description: "Запись на 10 коуч-сессий",
      containerText:
        "Программа из 10 коуч-сессий предлагает полный цикл коучинга, направленный на глубокую работу над вашими целями и задачами. Это идеальный выбор для тех, кто стремится к полной трансформации.",
      subtitle:
        "Готовы к полной перезагрузке? Пройдите 10 коуч-сессий и реализуйте свой потенциал на максимум.",
      seoTitle: "10 коуч-сессий с Ириной Медведевой",
      seoDescription:
        "Запишитесь на полный цикл из 10 коуч-сессий с Ириной Медведевой для глубокой работы над вашими целями.",
      seoKeywords: "коуч-сессии, полная трансформация, Ирина Медведева",
    },
    {
      type: "one-time-session",
      description: "Запись на разовую коуч-сессию",
      containerText:
        "Разовая коуч-сессия длительностью 60 минут. Идеально подходит для быстрого решения текущих задач или прояснения ситуации.",
      subtitle:
        "Нужно быстрое решение? Запишитесь на разовую сессию и получите ясность и поддержку в кратчайшие сроки.",
      seoTitle:
        "Разовая коуч-сессия с Ириной Медведевой | Быстрое решение задач",
      seoDescription:
        "Запишитесь на разовую коуч-сессию с Ириной Медведевой для быстрого решения текущих задач или прояснения ситуации. Получите профессиональную поддержку и ясность в кратчайшие сроки.",
      seoKeywords:
        "разовая коуч-сессия, коучинг, быстрое решение задач, Ирина Медведева",
    },
    {
      type: "psychologist-consultation",
      description: "Запись на консультацию психолога",
      containerText:
        "Консультация психолога по психосоматике длительностью 60 минут. Обсудим взаимосвязь вашего психологического состояния и физического здоровья, разработаем план действий для улучшения самочувствия.",
      subtitle:
        "Нужна помощь в восстановлении гармонии? Запишитесь на консультацию и начните улучшать своё здоровье и благополучие.",
      seoTitle: "Консультация психолога по психосоматике | Ирина Медведева",
      seoDescription:
        "Запишитесь на консультацию психолога по психосоматике с Ириной Медведевой. Обсудите взаимосвязь вашего психологического состояния и физического здоровья и разработайте план для улучшения самочувствия.",
      seoKeywords:
        "консультация психолога, психосоматика, здоровье, Ирина Медведева, психологическая помощь",
    },
  ];

  const getSessionData = (sessionType) => {
    return sessionsData.find((session) => session.type === sessionType) || {};
  };

  const sessionData = getSessionData(sessionType);
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    phone: "",
    preferredCommunication: "",
    message: "",
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });

    // Удаляем ошибку при изменении соответствующего поля
    if (errors[name]) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleFileChange = (e) => {
    setFiles(e.target.files);
    const fileNamesArray = Array.from(e.target.files).map((file) => file.name);
    setFileNames(fileNamesArray.join(", "));
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "Имя обязательно для заполнения";
    } else if (formData.firstName.length < 2) {
      newErrors.firstName = "Имя должно содержать минимум 2 символа";
    } else if (formData.firstName.length > 50) {
      newErrors.firstName = "Имя должно содержать не более 50 символов";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Телефон обязателен для заполнения";
    } else if (!/^\+?\d{10,15}$/.test(formData.phone)) {
      newErrors.phone = "Телефон введен некорректно";
    }

    if (!formData.preferredCommunication.trim()) {
      newErrors.preferredCommunication =
        "Выберите предпочтительный способ общения";
    }

    if (!formData.agree) {
      newErrors.agree = "Вы должны согласиться с правилами и условиями";
    }

    return newErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // Создаем объект FormData для отправки данных и файлов
      const data = new FormData();

      // Добавляем все поля формы в FormData
      for (const key in formData) {
        data.append(key, formData[key]);
      }

      // Добавляем sessionType в FormData
      data.append("sessionType", sessionType);

      // Добавляем файлы в FormData
      for (let i = 0; i < files.length; i++) {
        data.append("files[]", files[i]); // 'files[]' должно совпадать с именем поля на сервере
      }

      try {
        // Отправляем запрос на сервер
        // const response = await fetch("http://localhost:5000/sendToTelegram", {
        const response = await fetch(
          "https://yourcoachirina.ru/sendToTelegram",
          {
            method: "POST",
            body: data, 
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.text();
        console.log(result);

        setSubmissionStatus(
          "Форма успешно отправлена! Спасибо, Я свяжусь с Вами в ближайшее время."
        );

        setFormData({
          firstName: "",
          // email: "",
          phone: "",
          preferredCommunication: "",
          message: "",
          agree: false,
        });
        setErrors({});
        setFiles([]);
        setFileNames("");
      } catch (error) {
        console.error("There was an error sending the form!", error.message);
        setSubmissionStatus(
          "Произошла ошибка при отправке формы. Пожалуйста, попробуйте еще раз."
        );
      }
    }
  };

  return (
    <section className={`contact-form ${themeClass}`}>
      <PageHelmet
        title={sessionData?.seoTitle || "Запись на коуч-сессию"}
        description={
          sessionData?.seoDescription ||
          "Запишитесь на коуч-сессию с Ириной Медведевой."
        }
        keywords={
          sessionData?.seoKeywords || "коучинг, психология, Ирина Медведева"
        }
        image={MyEducationPhoto}
        canonicalUrl={`https://yourcoachirina.ru/contact-form/${sessionType}`}
      />

      <div className={`contact-form__container ${themeClass}`}>
        <h1 className={`contact-form__container-title ${themeClass}`}>
          {sessionData.description}
        </h1>
        <p className={`contact-form__container-text ${themeClass}`}>
          {sessionData.containerText}
        </p>
      </div>
      <div className={`contact-form__wrapper ${themeClass}`}>
        <h2 className={`contact-form__subtitle ${themeClass}`}>
          {sessionData.subtitle}
        </h2>

        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Ваше имя"
            />
            {errors.firstName && <p className="error">{errors.firstName}</p>}
          </div>

          <div>
            <PhoneInput
              country={"ru"}
              value={formData.phone}
              onChange={(phone) => setFormData({ ...formData, phone })}
              placeholder="Телефон"
              inputStyle={{
                width: "100%",
                paddingLeft: "58px",
                borderRadius: "0",
                fontSize: "0.7em",
              }}
              containerStyle={{ width: "100%" }}
            />
            {errors.phone && <p className="error">{errors.phone}</p>}
          </div>

          <div>
            <p className={`contact-form__block-text ${themeClass}`}>
              Где вам удобнее общаться?
            </p>
            <div className={`radio-group ${themeClass}`}>
              <label>
                <input
                  type="radio"
                  name="preferredCommunication"
                  value="WhatsApp"
                  checked={formData.preferredCommunication === "WhatsApp"}
                  onChange={handleChange}
                />
                WhatsApp
              </label>
              <label>
                <input
                  type="radio"
                  name="preferredCommunication"
                  value="Telegram"
                  checked={formData.preferredCommunication === "Telegram"}
                  onChange={handleChange}
                />
                Telegram
              </label>
              <label>
                <input
                  type="radio"
                  name="preferredCommunication"
                  value="Skype"
                  checked={formData.preferredCommunication === "Skype"}
                  onChange={handleChange}
                />
                Skype
              </label>
            </div>
            {errors.preferredCommunication && (
              <p className="error">{errors.preferredCommunication}</p>
            )}
          </div>

          <div className="contact-form__message">
            <textarea
              type="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Ваши мысли или вопросы (по желанию)"
            />
          </div>

          <div className="contact-form__block">
            <label
              className={`contact-form__block-text ${themeClass}`}
              htmlFor="files"
            >
              Если вам будет комфортнее, вы можете приложить документы или
              материалы:
            </label>
            <input
              type="file"
              name="files[]"
              id="files"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="files" className={`file-label ${themeClass}`}>
              Загрузить файлы
            </label>
            {fileNames && (
              <p className={`file-info ${themeClass}`}>
                Выбранные файлы: {fileNames}
              </p>
            )}
          </div>

          <div className="checkbox-container">
            <input
              className="contact-form__checkbox-box"
              type="checkbox"
              name="agree"
              checked={formData.agree}
              onChange={handleChange}
            />
            <p className="contact-form__checkbox-text">
              <label className={`contact-form__block-text ${themeClass}`}>
                Отправляя форму вы соглашаетесь с{" "}
                <Link to="/terms-and-conditions/">правилами и условиями </Link>
              </label>
            </p>
            {errors.agree && (
              <p className="contact-form__checkbox-error">{errors.agree}</p>
            )}
          </div>

          <button type="submit">Отправить</button>
        </form>
        {submissionStatus && (
          <p className="submission-status">{submissionStatus}</p>
        )}
      </div>
    </section>
  );
};

export default ContactForm;
