import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MyDiplomas.css";
import arrowRight from "../../images/arrowRight.png";
import { certificateData } from "../../utils/certificateData";

const MyDiplomas = ({ themeClass }) => {
  const containerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const container = containerRef.current;
    const itemCount = certificateData.length;

    container.style.setProperty("--item-count", itemCount * 2);
    container.style.setProperty("--scroll-duration", itemCount * 9);

    const handleHover = () => {
      container.style.animationPlayState = "paused";
    };

    const handleLeave = () => {
      container.style.animationPlayState = "running";
    };

    container.addEventListener("mouseenter", handleHover);
    container.addEventListener("mouseleave", handleLeave);

    return () => {
      container.removeEventListener("mouseenter", handleHover);
      container.removeEventListener("mouseleave", handleLeave);
    };
  }, []);

  const handleArrowClick = (direction) => {
    if (direction === "next") {
      containerRef.current.style.animationDirection = "normal";
      containerRef.current.style.animationPlayState = "running";
    } else if (direction === "prev") {
      containerRef.current.style.animationDirection = "reverse";
      containerRef.current.style.animationPlayState = "running";
    }
  };

  const handleImageClick = (data) => {
    setSelectedImage(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <section className={`my-diplomas ${themeClass}`} id="my-diplomas">
      <div className="my-diplomas__wrapper">
        <h1 className={`my-diplomas__title ${themeClass}`}>
          Дипломы и сертификаты
        </h1>
        <div className="my-diplomas__container">
          <div
            className="my-diplomas__inner-container animate"
            ref={containerRef}
          >
            {certificateData.concat(certificateData).map((data, index) => (
              <img
                key={index}
                className="my-diplomas__slide"
                src={data.img}
                alt={data.alt}
                onClick={() => handleImageClick(data)}
              />
            ))}
          </div>
        </div>
        <div className="my-diplomas__arrow-block">
          <img
            className="my-diplomas__arrow my-diplomas__left-arrow hover"
            src={arrowRight}
            alt="Влево"
            onClick={() => handleArrowClick("next")}
          />
          <img
            className="my-diplomas__arrow hover"
            src={arrowRight}
            alt="Вправо"
            onClick={() => handleArrowClick("prev")}
          />
        </div>
      </div>

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage.img}
              alt={selectedImage.alt}
              onClick={closeModal}
            />
            <button className="modal-close" onClick={closeModal}></button>
          </div>
        </div>
      )}
    </section>
  );
};

export default MyDiplomas;
