import React from "react";

import PageHelmet from "../PageHelmet";

import emotion5 from "../../images/emotion5.png";

const HealthPage = ({ themeClass, handleGoPrice }) => {
  return (
    <div className={`career-page ${themeClass}`}>
      <PageHelmet
        title="Здоровье как основа гармоничной жизни | Коучинг с Ириной Медведевой"
        description="Запишитесь на коуч-сессию с Ириной Медведевой для поиска путей развития здоровья тела и разума. Создай фундамент для достижения всех своих целей."
        keywords="коучинг здоровья, развитие личности, коуч для здоровья, внутренняя гармония, Ирина Медведева, гармония тела и разума, лайф коуч,  путь к здоровью"
        image={emotion5}
        canonicalUrl="https://yourcoachirina.ru/health/"
      />
      <div className="career-page__wrapper">
        <div className="career-page__title-block">
          <h1 className={`career-page__title ${themeClass}`}>
            Путь к здоровью: <br />
            как заботиться о теле и разуме
          </h1>
          <img
            className="career-page__img-small"
            src={emotion5}
            alt="Путь к здоровью:как заботиться о теле и разуме"
          />
        </div>
        <p className={`career-page__text ${themeClass}`}>
          Ваше здоровье — это фундамент для достижения всех остальных целей.
          Вместе мы выстроим устойчивые привычки, которые положительно повлияют
          на ваше физическое и эмоциональное состояние. Коучинг по здоровью
          поддержит вас на пути к гармонии между телом, разумом и душой.
          <br /> <br />
          Запишитесь на сессию, чтобы начать путь к здоровью и внутренней
          гармонии.
        </p>
        <button
          className={`career-page__btn-price hover ${themeClass}`}
          onClick={handleGoPrice}
        >
          Хочу в коучинг
        </button>
      </div>
    </div>
  );
};

export default HealthPage;
