import React from "react";

import PageHelmet from "../PageHelmet";

import strategy from "../../images/strategy.png";

const TimeManagementPage = ({ themeClass, handleGoPrice }) => {
  return (
    <div className={`career-page ${themeClass}`}>
      <PageHelmet
        title="Баланс между работой и личной жизнью | Коучинг с Ириной Медведевой"
        description="Запишитесь на коуч-сессию с Ириной Медведевой для поиска баланса между работой и личной жизнью. Научитесь управлять своим  временем."
        keywords="коучинг карьеры, баланс в жизни, коуч для бизнеса, личностный рост, Ирина Медведева, профессиональное развитие, бизнес коуч, лайф коуч"
        image={strategy}
        canonicalUrl="https://yourcoachirina.ru/time-management/"
      />
      <div className="career-page__wrapper">
        <div className="career-page__title-block">
          <h1 className={`career-page__title ${themeClass}`}>
            С пользой распределять время между работой и отдыхом
          </h1>
          <img
            className="career-page__img-small"
            src={strategy}
            alt="Значок анализ развития бизнеса"
          />
        </div>
        <p className={`career-page__text ${themeClass}`}>
          Баланс между работой и личной жизнью часто кажется недостижимым, но
          это возможно. С помощью коучинга вы научитесь управлять своим
          временем, не жертвуя ни карьерой, ни отдыхом. Сессии помогут вам
          расставить приоритеты и создать структуру дня, которая принесёт
          результат.
          <br /> <br />
          Запишитесь на сессию, чтобы научиться эффективно распределять время и
          жить в гармонии с собой.
        </p>
        <button
          className={`career-page__btn-price hover ${themeClass}`}
          onClick={handleGoPrice}
        >
          Хочу в коучинг
        </button>
      </div>
    </div>
  );
};
export default TimeManagementPage;
