import React from "react";

import "./Telegram.css";

import telegramQcode from "../../images/telegramQcode.png";

const phoneUserName = "irinamedvedeva_psy_coach";

const handleTelegramClick = () => {
  const telegramUrl = `https://t.me/${phoneUserName}`;
  window.open(telegramUrl, "_blank");
};

const Telegram = ({ themeClass }) => {
  return (
    <section className={`telegram ${themeClass}`} id="telegram">
      <div className="telegram__wrapper">
        <h1 className={`telegram__title ${themeClass}`}>
          Если Вы не нашли нужного ответа,
          <br /> напишите мне
        </h1>

        <div className="telegram__blocks">
          <button
            className={`contacts__btn contacts__btn-telegram hover ${themeClass}`}
            onClick={handleTelegramClick}
          >
            Telegram
          </button>
          <img
            className={`telegram__photo ${themeClass}`}
            src={telegramQcode}
            alt="Q-code telegram"
          />
        </div>
      </div>
    </section>
  );
};

export default Telegram;
