import React from "react";

import emotion4 from "../../images/emotion4.png";

import PageHelmet from "../PageHelmet";

const PersonalLifePage = ({ themeClass, handleGoPrice }) => {
  return (
    <div className={`career-page ${themeClass}`}>
      <PageHelmet
        title="Гармоничные отношения | Коучинг с Ириной Медведевой"
        description="Запишитесь на коуч-сессию с Ириной Медведевой для поиска ключей к внутреннему спокойствию и счастью. Разберитесь в своих сильных сторонах и начните эффективно общаться ."
        keywords="коучинг гармоничных отношений, развитие внутреннего спокойствия, коуч для жизни, личностный рост, Ирина Медведева, улучшения отношений, лайф коуч, гармония в личной жизни"
        image={emotion4}
        canonicalUrl="https://yourcoachirina.ru/personal-life/"
      />
      <div className="career-page__wrapper">
        <div className="career-page__title-block">
          <h1 className={`career-page__title ${themeClass}`}>
            Наладить гармоничную личную жизнь
          </h1>
          <img
            className="career-page__img-small"
            src={emotion4}
            alt="гармоничная личная жизнь"
          />
        </div>
        <p className={`career-page__text ${themeClass}`}>
          Гармоничные отношения — это ключ к внутреннему спокойствию и счастью.
          Но как их построить или восстановить, когда эмоции захлестывают? Наши
          коуч-сессии помогут вам глубже понять свои желания, научат эффективно
          общаться и дадут инструменты для улучшения отношений с близкими.
          <br /> <br />
          Запишитесь на сессию, чтобы обрести гармонию и поддержку в вашей
          личной жизни.
        </p>

        <button
          className={`career-page__btn-price hover ${themeClass}`}
          onClick={handleGoPrice}
        >
          Хочу в коучинг
        </button>
      </div>
    </div>
  );
};

export default PersonalLifePage;
