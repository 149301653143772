import React from "react";
import { useNavigate } from "react-router-dom";

import PageHelmet from "../PageHelmet";

import "./ScientificCenter.css";
import MyEducationPhoto from "../../images/MyEducationPhoto.png";

const ScientificCenter = ({ themeClass }) => {
  const navigate = useNavigate();
  const handleGoQuote = () => {
    navigate("/quote/");
  };

  const handleGoDailyPractice = () => {
    navigate("/daily-practice/");
  };

  return (
    <nav className={`scientific-center ${themeClass}`}>
      <PageHelmet
        title="Мастерская развития | Ирина Медведева."
        description="Ирина Медведева - коуч и психолог. Мастерская развития предлагает уникальные методы и инструменты для личностного роста и гармонии."
        keywords="коуч, психолог, Ирина Медведева, мастерская развития, личностный рост, гармония, колесо баланса, цитаты, мотивация"
        image={MyEducationPhoto}
        canonicalUrl="https://yourcoachirina.ru/scientific-center/"
      />

      <div className="scientific-center__wrapper">
        <h1 className={`scientific-center__title ${themeClass}`}>
          Добро пожаловать в мастерскую развития!
        </h1>
        <p className={`scientific-center__text ${themeClass}`}>
          На этой странице вы найдете несколько полезных практик, которые
          помогут развивать как физическое, так и эмоциональное состояние.
        </p>
        <div className="scientific-center__block">
          <h2 className={`scientific-center__subtitle ${themeClass}`}>
            Психологические цитаты для вдохновения
          </h2>

          <p className={`scientific-center__text ${themeClass}`}>
            Каждый день вы можете сгенерировать новую цитату, которая поможет
            вам задуматься о важных жизненных вопросах, вдохновит на позитивные
            изменения или просто поддержит в сложный момент. Эти цитаты
            направлены на повышение осознанности и внутренней гармонии. Они
            могут стать вашей ежедневной мотивацией и напоминанием о том, как
            важно быть в ладу с собой и окружающим миром.
          </p>
          <button
            className={`scientific-center__btn hover ${themeClass}`}
            onClick={handleGoQuote}
          >
            Узнать цитату
          </button>
        </div>
        <div className="scientific-center__block">
          <h2 className={`scientific-center__subtitle ${themeClass}`}>
            Духовные и физические упражнения
          </h2>

          <p className={`scientific-center__text ${themeClass}`}>
            Мы предлагаем вам возможность каждый день получать два упражнения:
            одно для духовного развития и одно для физического. Эти упражнения
            помогут вам улучшить ваше эмоциональное состояние и укрепить
            здоровье.
          </p>
          <button
            className={`scientific-center__btn hover ${themeClass}`}
            onClick={handleGoDailyPractice}
          >
            Узнать упражнения
          </button>
        </div>

        {/* <div className="scientific-center__block">
          <h2 className={`scientific-center__subtitle ${themeClass}`}>
          Статьи от коуча-психолога
          </h2>

          <p className={`scientific-center__text ${themeClass}`}>
          В разделе статей вы найдете материалы, которые помогут вам глубже
          понять себя, осознать свои цели и потребности, а также справиться с
          вызовами, которые встречаются на пути к гармонии. Каждая статья
          написана с вниманием к деталям, основана на профессиональном опыте и
          знаниях коуча. Читайте статьи, чтобы расширить свои знания, почерпнуть
          вдохновение и найти новые пути для самосовершенствования.
          </p>
          <button
            className={`scientific-center__btn hover ${themeClass}`}
            onClick={handleGoDailyPractice}
          >
            Узнать упражнения
          </button>
        </div> */}
      </div>
    </nav>
  );
};
export default ScientificCenter;
