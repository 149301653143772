import Certificate1 from "../images/CA-Certificate-of-Practice.webp";
import Certificate2 from "../images/CA-Certificate-2-step.webp";
import Certificate3 from "../images/CA-ID-card.webp";
import Certificate4 from "../images/CA-certificate-1st-stage.webp";
import Certificate5 from "../images/Certificate-ACC-ICF.webp";
import Certificate6 from "../images/Certificate-ASTER.webp";
import Certificate7 from "../images/Irina-Medvedeva.webp";
import Certificate8 from "../images/Diploma-in-psychosomatics.webp";


export const certificateData = [
  {
    id: 1, img: Certificate1, alt: "Диплом коуча",
  },
  {
    id: 2, img: Certificate2, alt: "Диплом коуча",
  },
  {
    id: 3, img: Certificate3, alt: "Диплом коуча",
  },
  {
    id: 4, img: Certificate4, alt: "Диплом коуча",
  },
  {
    id: 5, img: Certificate5, alt: "Диплом коуча",
  },
  {
    id: 6, img: Certificate6, alt: "Диплом коуча",
  },
  {
    id: 7, img: Certificate7, alt: "Диплом коуча",
  },
  {
    id: 8, img: Certificate8, alt: "Диплом коуча",
  },
];
