import React from "react";

import TrustMe from "../TrustMe/TrustMe";
import MyEducation from "../MyEducation/MyEducation";
import MyDiplomas from "../MyDiplomas/MyDiplomas";
import Reviews from "../Reviews/Reviews";
import PageHelmet from "../PageHelmet";

import "./AboutMe.css";
import MyEducationPhoto from "../../images/MyEducationPhoto.png";

const AboutMe = ({ themeClass, isDarkMode }) => {
  return (
    <nav className="about-me">
      <PageHelmet
        title="Обо мне - Ирина Медведева"
        description="Ирина Медведева психолог работающий в методе: Коучинг. Психологическая консультация онлайн."
        keywords="коуч, психолог, Ирина Медведева, коучинг, психологическая консультация"
        image={MyEducationPhoto}
        canonicalUrl="https://yourcoachirina.ru/about-me"
      />
      <TrustMe themeClass={themeClass} />
      <MyEducation themeClass={themeClass} />
      <MyDiplomas themeClass={themeClass} />
      <Reviews themeClass={themeClass} isDarkMode={isDarkMode} />
    </nav>
  );
};
export default AboutMe;
