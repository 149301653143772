import React, { useEffect } from "react";

import "./InfoAboutCookies.css";

import useHeaderHeight from "../../hooks/useHeaderHeight";

const InfoAboutCookies = ({
  themeClass,
  setCookieConsent,
  setIsCookieConsentVisible,
}) => {
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    const header = document.querySelector(".header");
    const headerHeight = header ? header.offsetHeight : 0;

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    return () => {
      localStorage.removeItem("allowPrivacyPolicy");
    };
  }, []);

  useEffect(() => {
    setIsCookieConsentVisible(false);
  }, [setIsCookieConsentVisible]);

  const handleGoBack = () => {
    localStorage.removeItem("cookieConsent");
    setCookieConsent(null);
    window.location.replace("/");
  };

  return (
    <section className={`about-cookies ${themeClass}`}>
      <div
        className="about-cookies__wrapper"
        style={{ paddingTop: `${headerHeight}px` }}
      >
        <h1 className={`about-cookies__title ${themeClass}`}>
          Использование куков на нашем сайте
        </h1>

        <h2 className={`about-cookies__subtitle ${themeClass}`}>
          Добро пожаловать на наш сайт! Мы хотим рассказать вам о куках, чтобы
          вы понимали, что это такое и зачем они нужны.
        </h2>
        <h3 className={`about-cookies__question ${themeClass}`}>
          Что такое куки?
        </h3>
        <p className={`about-cookies__text ${themeClass}`}>
          Куки (или cookies) – это небольшие файлы, которые сохраняются на вашем
          компьютере или мобильном устройстве, когда вы посещаете сайты. Они
          помогают сайтам работать правильно и могут запоминать некоторые ваши
          действия и настройки.
        </p>

        <h3 className={`about-cookies__question ${themeClass}`}>
          Зачем нужны куки на нашем сайте?
        </h3>
        <p className={`about-cookies__text ${themeClass}`}>
          Локальное хранилище: Мы используем локальное хранилище, чтобы
          сохранять некоторые данные на вашем устройстве. Например, это может
          быть информация о ваших настройках на нашем сайте, чтобы при следующем
          посещении вам не нужно было всё настраивать заново.
          <br /> <br />
          Аналитика: Мы используем куки от сервисов Яндекс Метрика и Google
          Analytics. Эти куки помогают нам понять, как вы используете наш сайт.
          Мы можем увидеть, какие страницы вам интересны, сколько времени вы
          проводите на сайте, и т.д. Эта информация помогает нам улучшать сайт,
          делая его более удобным и полезным для вас.
        </p>

        <h3 className={`about-cookies__question ${themeClass}`}>
          Безопасность и конфиденциальность
        </h3>
        <p className={`about-cookies__text ${themeClass}`}>
          Мы заботимся о вашей безопасности и конфиденциальности. Куки, которые
          мы используем, не содержат личную информацию, такую как ваше имя или
          электронная почта. Информация, которую мы собираем с помощью куков,
          используется только для улучшения работы нашего сайта.
        </p>

        <h3 className={`about-cookies__question ${themeClass}`}>
          Управление куками
        </h3>
        <ol>
          <li className={`about-cookies__text ${themeClass}`}>
            <strong>Настройки браузера</strong>: Зайдите в настройки вашего
            браузера. Обычно этот пункт можно найти в меню, которое обозначено
            значком с тремя точками или полосками в правом верхнем углу.
          </li>
          <li className={`about-cookies__text ${themeClass}`}>
            <strong>Конфиденциальность и безопасность</strong>: Найдите раздел,
            связанный с конфиденциальностью и безопасностью. Там вы увидите
            настройки, связанные с куками.
          </li>
          <li className={`about-cookies__text ${themeClass}`}>
            <strong>Удаление куков</strong>: Вы можете удалить уже сохраненные
            куки.
          </li>
          <li className={`about-cookies__text ${themeClass}`}>
            <strong>Блокировка куков</strong>: Вы можете настроить браузер так,
            чтобы он блокировал куки с определенных сайтов или всех сайтов.
          </li>
        </ol>

        <p className={`about-cookies__text ${themeClass}`}>
          Надеемся, что теперь вы лучше понимаете, зачем нужны куки и как они
          помогают сделать ваш опыт использования нашего сайта лучше.
          <br /> <br />С уважением, Команда поддержки.
        </p>

        <button
          className={`about-cookies__button hover ${themeClass}`}
          onClick={handleGoBack}
        >
          Вернуться на главную
        </button>
      </div>
    </section>
  );
};

export default InfoAboutCookies;
