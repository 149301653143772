import React, { useState, useEffect } from "react";

import PageHelmet from "../PageHelmet";

import { physicalExercisesData } from "../../utils/physicalExercisesData";
import { spiritualExercisesData } from "../../utils/spiritualExercisesData";

import "./DailyPractice.css";
import TrustMePhoto from "../../images/TrustMePhoto.png";

const DailyPractice = ({ themeClass }) => {
  const [physicalExercise, setPhysicalExercise] = useState(null);
  const [spiritualExercise, setSpiritualExercise] = useState(null);

  // Получаем текущую дату в формате "YYYY-MM-DD"
  const getCurrentDate = () => new Date().toISOString().split("T")[0];

  // Загружаем упражнения из localStorage или начинаем с пустого массива
  useEffect(() => {
    const savedPhysicalExercise = JSON.parse(
      localStorage.getItem("physicalExercise")
    );
    const savedSpiritualExercise = JSON.parse(
      localStorage.getItem("spiritualExercise")
    );
    const lastDate = localStorage.getItem("exerciseDate");

    if (lastDate === getCurrentDate()) {
      if (savedPhysicalExercise) setPhysicalExercise(savedPhysicalExercise);
      if (savedSpiritualExercise) setSpiritualExercise(savedSpiritualExercise);
    }
  }, []);

  // Функция для получения случайного упражнения
  const getRandomExercise = (exercises) => {
    const randomIndex = Math.floor(Math.random() * exercises.length);
    return exercises[randomIndex];
  };

  // Обработчик нажатия на кнопку
  const handleGetExercises = () => {
    if (physicalExercise && spiritualExercise) {
      alert("Вы уже получили 2 упражнения на сегодня.");
      return;
    }

    const newPhysicalExercise = getRandomExercise(physicalExercisesData);
    const newSpiritualExercise = getRandomExercise(spiritualExercisesData);

    setPhysicalExercise(newPhysicalExercise);
    setSpiritualExercise(newSpiritualExercise);

    localStorage.setItem(
      "physicalExercise",
      JSON.stringify(newPhysicalExercise)
    );
    localStorage.setItem(
      "spiritualExercise",
      JSON.stringify(newSpiritualExercise)
    );
    localStorage.setItem("exerciseDate", getCurrentDate());
  };

  return (
    <section className={`daily-practice ${themeClass}`} id="daily-practice">
      <PageHelmet
  title="Ежедневные упражнения для тела и души | Ирина Медведева"
  description="Попробуйте случайные упражнения для тела и души, чтобы поддерживать физическое и эмоциональное здоровье. Легкие практики для каждого дня помогут вам оставаться в гармонии."
  keywords="ежедневные упражнения, духовные практики, упражнения для тела, коучинг по здоровью, Ирина Медведева, физическая активность"
  image={TrustMePhoto}
  canonicalUrl="https://yourcoachirina.ru/daily-practice/"
/>

      <div className={`daily-practice__wrapper ${themeClass}`}>
        <h2 className={`daily-practice__title ${themeClass}`}>
         Ежедневно получайте упражнение для тела и 
          упражнение для души.         
        </h2>

        <button
          className={`daily-practice__button ${themeClass}`}
          onClick={handleGetExercises}
        >
          Узнать упражнения
        </button>

        <div className="daily-practice__exercises">
          {physicalExercise && (
            <div className="daily-practice__exercise-block">
              <h3 className={`daily-practice__exercise-title ${themeClass}`}>Физическое упражнение: «{physicalExercise.name}»</h3>
              <p className={`daily-practice__exercise-text ${themeClass}`}>{physicalExercise.description}</p>
            </div>
          )}

          {spiritualExercise && (
            <div className="daily-practice__exercise-block">
              <h3 className={`daily-practice__exercise-title ${themeClass}`}>Духовное упражнение: «{spiritualExercise.name}»</h3>
              <p className={`daily-practice__exercise-text ${themeClass}`}>{spiritualExercise.description}</p>
            </div>
          )}
        </div>
        <p className={`daily-practice__description ${themeClass}`}>
          Эти упражнения вы можете выполнять в течение дня, в те моменты, когда
          почувствуете в этом необходимость. 
          <br /><br />Важно выполнять их осознанно,
          концентрируясь на своих ощущениях и внутреннем состоянии.
          <br /> Не бойтесь
          повторять упражнения несколько раз в день — это поможет вам глубже
          проработать физическое и эмоциональное состояние, сохраняя баланс и
          гармонию.
        </p>
      </div>
    </section>
  );
};

export default DailyPractice;
